import Link from "next/link";
import { useRouter } from "next/router";

export default function Error404(): JSX.Element {
  const router = useRouter();

  const textValue = (): JSX.Element => {
    if (router.asPath.includes("/p/")) {
      return (
        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
          <h1 className="text-4xl font-black tracking-tight text-gray-900 sm:text-5xl">Oops!</h1>
          <p className="mt-4  text-xl text-gray-500">
            Looks like the job you&apos;re looking for no longer exists (it probably expired).
          </p>
          <p className="mt-1  text-xl text-gray-500">
            Luckily, <b>121,534</b> jobs at <b>5,212</b> amazing companies do. Let&apos;s help you find your dream job.
          </p>
        </div>
      );
    }
    if (router.asPath.includes("/l/") || router.asPath.includes("/lists/")) {
      return (
        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
          <h1 className="text-4xl font-black tracking-tight text-gray-900 sm:text-5xl">Job list doesn&apos;t exist</h1>
          <p className="mt-1 text-base text-gray-500">This job list has either been removed or doesn&apos;t exist.</p>
        </div>
      );
    }
    if (router.asPath.includes("/c/") || router.asPath.includes("/company/")) {
      return (
        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
          <h1 className="text-4xl font-black tracking-tight text-gray-900 sm:text-5xl">Company page not found</h1>
          <p className="mt-1 text-base text-gray-500">
            This company either does not have a page yet or doesn&apos;t exist.
          </p>
        </div>
      );
    }
    return (
      <div className="sm:border-l sm:border-gray-200 sm:pl-6">
        <h1 className="text-4xl font-black tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
        <p className="mt-1 text-base text-gray-500">Please check the URL in the address bar and try again.</p>
      </div>
    );
  };

  const buttonValue = (): JSX.Element => {
    if (router.asPath.includes("/p/")) {
      return (
        <Link
          className="inline-flex items-center rounded-md border border-transparent bg-primary-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:ring-offset-2"
          href="/jobs"
        >
          Browse Jobs
        </Link>
      );
    }
    if (router.asPath.includes("/l/") || router.asPath.includes("/lists/")) {
      return (
        <Link
          className="inline-flex items-center rounded-md border border-transparent bg-primary-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:ring-offset-2"
          href="/lists"
        >
          Browse Job Lists
        </Link>
      );
    }
    if (router.asPath.includes("/c/") || router.asPath.includes("/company/")) {
      return (
        <Link
          className="inline-flex items-center rounded-md border border-transparent bg-primary-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:ring-offset-2"
          href="/companies"
        >
          Browse Companies
        </Link>
      );
    }
    return (
      <Link
        className="inline-flex items-center rounded-md border border-transparent bg-primary-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:ring-offset-2"
        href="/dashboard"
      >
        Dashboard
      </Link>
    );
  };

  return (
    <div className="min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-black text-primary-400 sm:text-5xl">404</p>
          <div className="sm:ml-6">
            {textValue()}
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              {buttonValue()}
              <a
                className="inline-flex items-center rounded-md border border-transparent bg-primary-100 px-4 py-2 text-sm font-medium text-primary-400 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-50 focus:ring-offset-2"
                href="mailto:support@simplify.jobs"
              >
                Contact support
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
